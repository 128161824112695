import React, { Component } from 'react';
import { Helmet } from "react-helmet";
import ReactMarkdown from 'react-markdown';
import ogImg from '../../assets/img/og.jpg';
import Footer from '../../components/shared/Footer';

class FreeText extends Component {

  render() {
    if (this.props.pageFreeTextQuery && this.props.pageFreeTextQuery.loading) {
      return <div className="u-hidden">Loading</div>
    } else if (this.props.pageFreeTextQuery && this.props.pageFreeTextQuery.error) {
      return <div className="u-hidden">Error</div>
    } else {
      this.data = this.props.pageFreeTextQuery.thisFreeText[0];

      if (!this.data) {
        window.location.href = "/404";
        return (null);
      } else {
        return (
          [
            <main key="freeText" className="freeText">
              <Helmet>
                <title>{this.data.title}</title>
                <meta name="description" content={this.data.description}/>
                <meta property="og:title" content={this.data.title}/>
                <meta property="og:description" content={this.data.description}/>
                <meta property="og:image" content={ogImg}/>
                <meta property="og:url" content={window.location.href}/>
              </Helmet>
              <section className="freeText__header">
                <h1 className="freeText__header__title t-pageTitle">{this.data.title}</h1>
                <h2 className="freeText__header__description t-tag--large">{this.data.description}</h2>
              </section>
              <section className="freeText__body t-copy">
                <ReactMarkdown className="markdown-body" source={this.data.body}/>
              </section>
            </main>,
            <Footer key="Footer" mainNavigation={this.props.pageFreeTextQuery.footer[0].mainNavigation} contactUsPage={this.props.pageFreeTextQuery.footer[0].contactUsPage} />
          ]
        );
      }
    }
  }
}

export default FreeText;