import React, { Component } from 'react';
import { graphql } from '@apollo/client/react/hoc';
import {gql} from '@apollo/client';

export default function pageOurVenuesWithData(WrappedComponent, options = {}) {
  const HOC = class extends Component {
    constructor(props) {
      super(props);
      this.wrapped = graphql(gql `
              query pageOurVenuesQuery {
                thisPageOurVenues: pageOurVenues(q:"fields.route=${props.match.path.slice(1)}"){
                  route
                  navigationName
                  introHeader
                  introBody
                  introBackground {
                    url
                  }
                  partnerLogos {
                    url
                    title
                  }
                  venues {
                    route
                    hero {
                      url
                      title
                    }
                    name
                    overviewBody
                  }
                }
                footer: globalSites {
                  mainNavigation {
                    footerHeader
                    footerBody
                    footerLinks {
                      __typename
                      ... on PageFreeText {
                        title
                        route
                      }
                      __typename
                      ... on ComponentFooterLink {
                        title
                        route
                      }
                    }
                    buttonCTA
                    phoneNumber
                    copyrightHolder
                  }
                  contactUsPage {
                    route
                  }
                }
              }
          `, { name: 'pageOurVenuesQuery' })(WrappedComponent);
    }

    render() {
      const Wrapped = this.wrapped;
      return <Wrapped {...this.props}/>;
    }
  };

  return HOC;
}