import React, { Component } from 'react';
import { graphql } from '@apollo/client/react/hoc';
import {gql} from '@apollo/client';

export default function homeWithData(WrappedComponent, options = {}) {
  const HOC = class extends Component {
    constructor(props) {
      super(props);
      this.wrapped = graphql(gql `query homePageQuery {
        globalSites {
          mainNavigation {
            primaryNavigationLinks {
              __typename
              ... on PageWhoWeAre {
                route
                navigationName
              }
              __typename
              ... on PageWhatWeDo {
                route
                navigationName
              }
              __typename
              ... on PageOurVenues {
                route
                navigationName
              }
            }
            footerHeader
            footerBody
            footerLinks {
              __typename
              ... on PageFreeText {
                title
                route
              }
              __typename
              ... on ComponentFooterLink {
                title
                route
              }
            }
            buttonCTA
            phoneNumber
            copyrightHolder
          }
          contactUsPage {
            route
          }
          homePage {
            introBackgrounds {
              title
              description
              url
            }
            introHeader
            introVideoCta
            introVideoUrl
            introBody
            featuredCaseStudies {
              serviceName
              hero {
                url
              }
              header
              heroCaseStudy {
                route
              }
              servicePage {
                route
              }
            }
            venuesHeader
            venuesCarousel {
              marketingDescription
              marketingImage {
                url
                title
              }
              name
              route
            }
            caseStudies {
              __typename
              ... on ComponentQuote {
                quote
                attribution
                relevance
                image {
                  title
                  url
                }
                isGreen
                isTweet
              }
              __typename
              ... on PageCaseStudy {
                route
                name
                marketingImage {
                  url
                }
                venue {
                  name
                  route
                }
                relatedServices {
                  name
                  route
                }
              }
            }
          }
        }
      }
      `, { name: 'homePageQuery' })(WrappedComponent);
    }

    render() {
      const Wrapped = this.wrapped;
      return <Wrapped {...this.props}/>;
    }
  };

  return HOC;
}