import React, { Component } from 'react';
import { graphql } from '@apollo/client/react/hoc';
import {gql} from '@apollo/client';

export default function personWithData(WrappedComponent, options = {}) {
  const HOC = class extends Component {
    constructor(props) {
      super(props);
      this.wrapped = graphql(gql `
            query PagePersonQuery {
              thisPerson: pagePeople(q:"fields.route=${props.match.params.matchedRoute}"){
                route
                name
                title
                titleCubs
                image {
                  url
                }
                shortBio
                longBio
                sys {
                  id
                }
              }
              footer: globalSites {
                mainNavigation {
                  footerHeader
                  footerBody
                  footerLinks {
                    __typename
                    ... on PageFreeText {
                      title
                      route
                    }
                    __typename
                    ... on ComponentFooterLink {
                      title
                      route
                    }
                  }
                  buttonCTA
                  phoneNumber
                  copyrightHolder
                }
                contactUsPage {
                  route
                }
              }
              pagePeople(q:"fields.route[ne]=${props.match.params.matchedRoute}", limit:5){
                route
                name
                title
                titleCubs
                image {
                  url
                }
                sys {
                  id
                }
              }
            }
        `, { name: 'pagePersonQuery' })(WrappedComponent);
    }

    render() {
      const Wrapped = this.wrapped;
      return <Wrapped {...this.props}/>;
    }
  };

  return HOC;
}