import React, { Component } from 'react';
import Link from '../../components/Link';
import ModalVideo from "../ReactModalVideo";

class ModuleFeatures extends Component {
  state = {
    imageClass: ''
  }

  componentDidMount() {
    if (this.props.features[0].image !== null) {
      this.setState({
        imageClass: 'moduleFeatures__container--img'
      });
    }
  }

  componentWillMount() {
    var tArray = new Array(20); //more than you would expect there to ever be.
    tArray.fill(false);
    this.setState({ reelOpen: tArray });
  }

  playReel(index) {
    var reelOpen = this.state.reelOpen.slice();
    reelOpen.fill(false);
    reelOpen[index] = true;
    this.setState({ reelOpen: reelOpen });
  }

  closeVideo() {
    var reelOpen = this.state.reelOpen.slice();
    reelOpen.fill(false);
    this.setState({ reelOpen: reelOpen });
  }

  render() {
    return (
      <section className="moduleFeatures">
        <div className={`moduleFeatures__container ${this.state.imageClass}`}>
          <h2 className="t-sectionTitle--large">
            {this.props.header}
          </h2>
          <p className="moduleFeatures__copy t-copy">
            {this.props.body}
          </p>
          <div>
            {
              this.props.features.map(function (feature, i) {
                if (feature.videoUrl) {
                  return <ModalVideo key={i} isOpen={this.state.reelOpen[i]} video={feature.videoUrl} onClose={() => this.closeVideo()} />
                } else {
                  return (null)
                }
              }, this)
            }
          </div>

          <div className="moduleFeatures__features">
            {
              this.props.features.map((feature, i) => (
                <div key={i} className="moduleFeatures__feature">
                  {
                    feature.image &&
                    <div className="moduleFeatures__feature__thumbnailContainer">
                      <img className="moduleFeatures__feature__thumbnail" src={feature.image.url} alt={feature.image.title} />
                    </div>
                  }
                  <div className="moduleFeatures__feature__content">
                    <h3 className="moduleFeatures__feature__title t-cardTitle">
                      {feature.header}
                    </h3>
                    {
                      feature.description &&
                      <p className="moduleFeatures__feature__description t-default">
                        {feature.description}
                      </p>
                    }
                    {
                      feature.videoUrl &&
                      <div className="moduleFeatures__video">
                        <button key={`${feature.videoUrl}-${i}`} className="moduleFeatures__cta t-cta--link link" onClick={() => this.playReel(i)}>
                          Watch the Video
                          <svg className="moduleFeatures__icon-play">
                            <use xlinkHref="#icon-play" href="#icon-play"></use>
                          </svg>
                        </button>
                      </div>
                    }
                    {feature.linkUrl && <Link to={feature.linkUrl} className="moduleFeatures__cta t-cta--link link">
                      Learn More
                    </Link>}
                  </div>
                </div>
              ))
            }
          </div>
        </div>
      </section>
    );
  }
}

export default ModuleFeatures;