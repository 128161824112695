import React, { Component } from 'react';

class HeroCarousel extends Component {

  render() {
    const images = this.props.introBackgrounds;
    //?&q=100&fm=jpg&fl=progressive&w="+window.innerWidth+"&h="+window.innerHeight
    return (
      <div className="heroCarousel">
        {images.map((image, i) => (
          <div className="heroCover" key={i}>
            <div className="heroCover__child heroCover__child--top">
              <div className="heroCover__imgContainer">
                <img
                  src={image.url}
                  alt={image.title}
                  className="heroCover__img"
                />
              </div>
            </div>
            <div className="heroCover__child heroCover__child--bot">
              <div className="heroCover__imgContainer">
                <img
                  src={image.url}
                  alt={image.title}
                  className="heroCover__img"
                />
              </div>
            </div>
          </div>
        ))}
        <div className="heroCover">
          <div className="heroCover__child heroCover__child--top">
            <div className="heroCover__imgContainer">
              <img
                src={images[0].url}
                alt={images[0].title}
                className="heroCover__img"
              />
            </div>
          </div>
          <div className="heroCover__child heroCover__child--bot">
            <div className="heroCover__imgContainer">
              <img
                src={images[0].url}
                alt={images[0].title}
                className="heroCover__img"
              />
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default HeroCarousel;