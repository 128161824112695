import React, { Component } from 'react';

class CaseStudyTweet extends Component {
  render() {
    return (
      <div className="caseStudyTweet">
        <div className="caseStudyTweet__img">
          <img src={this.props.quote.image.url} alt={this.props.quote.image.title} />
        </div>
        <div className="caseStudyTweet__quote">
          <div className="caseStudyTweet__line">
            <svg className="icon-twitter">
              <use xlinkHref="#twitter-icon" href="#twitter-icon"></use>
            </svg>
          </div>
          <q className="t-sectionTitle--large">
            {this.props.quote.quote}
          </q>
          <p className="caseStudyTweet__attribution t-tag--large">
            {this.props.quote.attribution}
          </p>
        </div>
      </div>
    )
  }
}

export default CaseStudyTweet;