import React, { Component } from 'react';

class ModuleStats extends Component {
  render() {
    return (
      <section className="venueStats">
        <div className="venueStats__container">
          <div className="venueStats__container--inner">
            <h1 className="t-sectionTitle--large">
              {this.props.header}
            </h1>
            <p className="venueStats__copy t-copy">
              {this.props.body}
            </p>
            <div className="venueStats__stats">
              {
                this.props.stats.map((stat, i) => (
                  <div className="venueStats__stat" key={i}>
                    <h2 className="t-number--large">
                      {stat.prefix}{stat.finalAmount}{stat.abbreviation}
                    </h2>
                    <p className="t-sectionTitle--small">
                      {stat.description}
                    </p>
                  </div>
                ))
              }
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default ModuleStats;