import React, { Component } from 'react';
import { graphql } from '@apollo/client/react/hoc';
import {gql} from '@apollo/client';

export default function venueWithData(WrappedComponent, options = {}) {
  const HOC = class extends Component {
    constructor(props) {
      super(props);
      this.wrapped = graphql(gql `
        query venuePageQuery {
          thisVenue: pageVenues(q: "fields.route=${props.match.params.matchedRoute}") {
            name
            route
            location
            hero {
              url
            }
            overviewBody
            linkCta
            linkUrl
            image360 {
              url
              title
              sys {
                id
              }
            }
            footerImage {
              url
            }
            footerVenues{
              marketingImage{
                title
                url
              }
              marketingDescription
              route
            }
            _backrefs {
              pageCaseStudies__via__venue{
                name
                route
                marketingImage{
                  title
                  url
                }
              }
            }
            bodySections {
              __typename
              ... on ModuleAside {
                header
                body
                linkText
                linkUrl
                bulletPoints
                isWide
              }
              __typename
              ... on ModuleChefs {
                header
                body
                chefs {
                  name
                  bio
                  photo {
                    title
                    url
                  }
                }
              }
              __typename
              ... on ModuleConcerts {
                header
                headliners
                images {
                  title
                  description
                  url
                }
                supportingActs
              }
              __typename
              ... on ModuleFeatures {
                header
                body
                linkText
                linkUrl
                features {
                  image {
                    title
                    url
                  }
                  header
                  description
                  linkUrl
                  videoUrl
                }
              }
              __typename
              ... on ModuleGallery {
                headerBodyTextLocation
                header
                body
                linkText
                linkUrl
                images {
                  title
                  url
                }
                fullWidthBottomImage
                caption
                image360 {
                  url
                  title
                  sys {
                    id
                  }
                }
              }
              __typename
              ... on ModuleQuote {
                header
                body
                linkText
                linkUrl
                quote {
                  quote
                  attribution
                  relevance
                  image {
                    title
                    url
                  }
                  isGreen
                  isTweet
                }
              }
              __typename
              ... on ModuleStats {
                header
                body
                linkText
                linkUrl
                stats {
                  prefix
                  finalAmount
                  abbreviation
                  description
                }
                textOnTop
              }
              __typename
              ... on ModuleVideo {
                header
                videoUrl
              }
            }
          }
          footer: globalSites {
            mainNavigation {
              footerHeader
              footerBody
              footerLinks {
                __typename
                ... on PageFreeText {
                  title
                  route
                }
                __typename
                ... on ComponentFooterLink {
                  title
                  route
                }
              }
              buttonCTA
              phoneNumber
              copyrightHolder
            }
            contactUsPage {
              route
            }
          }
        }
        `, { name: 'venuePageQuery' })(WrappedComponent);
    }

    render() {
      const Wrapped = this.wrapped;
      return <Wrapped {...this.props}/>;
    }
  };

  return HOC;
}