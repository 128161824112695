import React, { Component } from 'react';
import { Helmet } from "react-helmet";
import 'aframe';
import { Entity, Scene } from 'aframe-react';

class Image360 extends Component {

  data = {
    name: "",
    image360: {
      url: ""
    }
  };

  state = {
    loadedText: ""
  }

  loaded = (event) => {
    this.setState({ loadedText: "loaded" });
  }

  goBack = () => {

    this.props.history.goBack();
  }

  componentDidMount() {
    var aScene = document.querySelector('a-assets');
    if (aScene) {
      aScene.addEventListener("loaded", this.loaded);
    }
  }

  componentWillUnmount() {
    var aHTML = document.querySelector(".a-html");
    aHTML.classList.remove("a-html");

    var aBody = document.querySelector(".a-body");
    aBody.classList.remove("a-body");
  }

  render() {
    if (this.props.pageImage360Query && this.props.pageImage360Query.loading) {} else if (this.props.pageImage360Query && this.props.pageImage360Query.error) {} else {
      if (this.props.pageImage360Query.moduleGalleries.length > 0) {
        this.data = this.props.pageImage360Query.moduleGalleries[0];
      } else {
        this.data = this.props.pageImage360Query.pageVenues[0];
      }
    }

    if (!this.data) {
      window.location.href = "/404";
      return (null);
    } else {

      return (
        <main className={`Image360 ${this.state.loadedText}`}>
          <Helmet>
            <title>{this.data.name + ": 360 View"}</title>
            <meta name="description" content={this.data.image360.title}/>
            <meta property="og:title" content={this.data.name + ": 360 View"}/>
            <meta property="og:url" content={window.location.href}/>
            <meta property="og:image" content={this.data.image360.url+"?fm=jpg&q=100&fl=progressive&w=1280&h=640&fit=fill"}/>
            <meta property="og:description" content={this.data.image360.title}/>
          </Helmet>
          <div className={`loader ${this.state.loadedText}`}>
            <h2>Loading</h2>
            <div className="progress">
              <div className="indeterminate"></div>
            </div>
          </div>
          <div className="curtain"/>
          <div className="imageNav">
            <button onClick={this.goBack}>
              <svg className="icon-back">
                <use xlinkHref="#arrow-left-small--white" href="#arrow-left-small--white"></use>
              </svg>
              <span className="name">{ this.data.name }</span>
            </button>
          </div>
          <Scene className={this.state.loadedText}>
            <Entity primitive='a-assets'>
              <img id="img360" crossOrigin="anonymous" alt="" src={this.data.image360.url}></img>
            </Entity>
            <Entity primitive='a-sky' src="#img360"/>
          </Scene>
        </main>
      );
    }
  }
}

export default Image360;