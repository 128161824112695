import React, { Component } from 'react';
import classnames from 'classnames';

class VenueAside extends Component {
  render() {
    const classes = classnames(
      'venueAside__container',
      {
        'is-wide': this.props.isWide
      }
    );

    return (
      <section className="venueAside">
        <div className={classes}>
          <div className="venueAside__column">
            <h2 className="t-sectionTitle--large">
              {this.props.header}
            </h2>
            {
              this.props.body !== null &&
              <p className="venueAside__copy t-copy">
                {this.props.body}
              </p>
            }
          </div>
          <div className="venueAside__column">
            <ul className="venueAside__list t-cardTitle">
              {
                this.props.bulletPoints.map((bulletPoint, i) => (
                  <li key={i} className="venueAside__listItems">
                    <span>{bulletPoint}</span>
                  </li>
                ))
              }
            </ul>
          </div>
        </div>
      </section>
    )
  }
}

export default VenueAside;