import React, { Component } from 'react';
import ReactPlayer from 'react-player'

class ModuleVideo extends Component {

  render() {
    return (
      <section className="moduleVideo">
        {
          this.props.video &&
          <ReactPlayer className="moduleVideo__video" url={this.props.video} />
        }
        <h2 className="moduleVideo__title t-cardTitle">
          {this.props.header}
        </h2>
      </section>
    )
  }
}

export default ModuleVideo;