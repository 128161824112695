import React, { Component } from 'react';
import classnames from 'classnames';
import Link from '../../components/Link';
import Swiper from 'swiper/dist/js/swiper.js';

class VenueCaseStudyCarousel extends Component {
  componentDidMount() {
    try {
      new Swiper('.venueCaseStudyCarousel__container', {
        pagination: {
          el: '.venueCaseStudyCarousel__slide__pagination',
          type: 'custom',
          renderCustom: function (swiper, current, total) {
            if (current < 10 && total < 10) {
              return '<span>0' + current + ' / 0' + total + '</span>';
            } else if (current >= 10 && total >= 10) {
              return '<span>' + current + ' / ' + total + '</span>';
            } else if (total >= 10) {
              return '<span>0' + current + ' / ' + total + '</span>';
            }
          }
        },
        navigation: {
          nextEl: '.venueCaseStudyCarousel-next',
          prevEl: '.venueCaseStudyCarousel-prev'
        },
        loop: true,
        slidesPerView: 'auto',
        grabCursor: true,
        longSwipesRatio: 0.2,
        centeredSlides: true,
        breakpoints: {
          768: {
            spaceBetween: 4,
          },
          3000: {
            spaceBetween: 8,
          }
        }
      });
    } catch (error) {
      //console.error('error: ', error);
    }
  }

  pathIsExactly = (str) => {
    return window.location.pathname === str;
  }

  render() {
    const classes = classnames(
      'venueCaseStudyCarousel', {
        'venueCaseStudyCarousel--wrigley': this.pathIsExactly('/our-venues/wrigley-field')
      }
    )

    return (
      <section className={classes}>
        <div className="venueCaseStudyCarousel__outerContainer">
          <div className="venueCaseStudyCarousel-prev">
            <svg className="icon-arrow arrow-left-large">
              <use xlinkHref="#arrow-left-large" href="#arrow-left-large"></use>
            </svg>
          </div>

          <div className="swiper-container venueCaseStudyCarousel__container">
            <div className="swiper-wrapper venueCaseStudyCarousel__wrapper">
              {
                this.props.backrefs.map((backref, i) => (
                  <Link
                    key={i}
                    to={`/case-studies/${backref.route}`}
                    className="swiper-slide venueCaseStudyCarousel__slide"
                  >
                    <div
                      className="venueCaseStudyCarousel__img"
                      style={{
                        backgroundImage: `url(${backref.marketingImage.url})`
                      }}
                      title={backref.marketingImage.title}
                    />
                    <div className="venueCaseStudyCarousel__slide__info">
                      <div className="venueCaseStudyCarousel__slide__pagination t-number--small"></div>
                      <h2 className="t-cardTitle venueCaseStudyCarousel__slide__label">
                        {backref.name}
                      </h2>
                    </div>
                  </Link>
                ))
              }
            </div>
          </div>

          <div className="venueCaseStudyCarousel-next">
            <svg className="icon-arrow arrow-right-large">
              <use xlinkHref="#arrow-right-large" href="#arrow-right-large"></use>
            </svg>
          </div>
        </div>
      </section>
    )
  }
}

export default VenueCaseStudyCarousel;