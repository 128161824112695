import React, { Component } from 'react';
import Link from '../../components/Link';
import { Helmet } from "react-helmet";
import ReactMarkdown from 'react-markdown';
import ogImg from '../../assets/img/og.jpg';
import Footer from '../../components/shared/Footer';

class ErrorPage extends Component {

  render() {
    if (this.props.pageErrorPageQuery && this.props.pageErrorPageQuery.loading) {
      return <div className="u-hidden">Loading</div>
    } else if (this.props.pageErrorPageQuery && this.props.pageErrorPageQuery.error) {
      return <div className="u-hidden">Error</div>
    } else {
      this.data = this.props.pageErrorPageQuery.thisErrorPage[0];
      return (
        [
          <main key="ErrorPage" className="freeText">
            <Helmet>
              <title>{this.data.title + ": " + this.data.description}</title>
              <meta name="description" content={this.data.description}/>
              <meta property="og:title" content={this.data.title + ": " + this.data.description}/>
              <meta property="og:description" content={this.data.description}/>
              <meta property="og:image" content={ogImg}/>
              <meta property="og:url" content={window.location.href}/>
            </Helmet>
            <section className="freeText__header">
              <h1 className="freeText__header__title t-pageTitle">{this.data.title}</h1>
              <h2 className="freeText__header__description t-tag--large">{this.data.description}</h2>
            </section>
            <section className="freeText__body t-copy">
              <ReactMarkdown className="markdown-body" source={this.data.body}/>
              <br/>
              <Link to="/" className="link">Click here</Link> to return to the home page.
            </section>
          </main>,
          <Footer key="Footer" mainNavigation={this.props.pageErrorPageQuery.footer[0].mainNavigation} contactUsPage={this.props.pageErrorPageQuery.footer[0].contactUsPage} />
        ]
      );
    }
  }
}

export default ErrorPage;