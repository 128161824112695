import React, { Component } from 'react';
import Link from '../../components/Link';
import { Helmet } from 'react-helmet';
import ReactMarkdown from 'react-markdown';
import ogImg from '../../assets/img/og.jpg';
import Footer from '../../components/shared/Footer';

class Person extends Component {

  render() {
    if (this.props.pagePersonQuery && this.props.pagePersonQuery.loading) {
      return <div className="u-hidden">Loading</div>
    } else if (this.props.pagePersonQuery && this.props.pagePersonQuery.error) {
      return <div className="u-hidden">Error</div>
    } else {
      this.data = this.props.pagePersonQuery.thisPerson[0];

      if (!this.data) {
        window.location.href = "/404";
        return (null);
      } else {
        return (
          [
            <main key="Person" className="page__person">
              <Helmet>
                <title>{this.data.name}</title>
                <meta name="description" content={this.data.shortBio}/>
                <meta property="og:title" content={this.data.name}/>
                <meta property="og:description" content={this.data.shortBio}/>
                <meta property="og:url" content={window.location.href}/>
                <meta property="og:image" content={ogImg}/>
              </Helmet>
              <header className="person__header">
                <div className="person__header__container">
                  <div className="person__header__container--inner">
                    <Link to="/who-we-are" className="icon-back__container">
                      <svg className="icon-back icon-back--large">
                        <use xlinkHref="#back-arrow" href="#back-arrow"></use>
                      </svg>
                      <svg className="icon-back icon-back--small">
                        <use xlinkHref="#arrow-left-small--red" href="#arrow-left-small--red"></use>
                      </svg>
                    </Link>
                    <h1 className="person__header__name t-pageTitle">
                      {this.data.name}
                    </h1>
                    <h2 className="person__header__title t-tag--large">
                      {this.data.title}
                    </h2>
                  </div>
                </div>
              </header>

              <section className="person__shortBio">
                <div className="person__shortBio__container">
                  <div className="person__shortBio__container--inner">
                    <p className="person__shortBio__text t-intro">
                      {this.data.shortBio}
                    </p>
                    <img className="person__profile" src={this.data.image.url} alt={this.data.name}/>
                  </div>
                </div>
              </section>

              <section className="person__longBio t-copy">
                <div className="person__longBio__container">
                  <ReactMarkdown className="person__longBio__text markdown-body" source={this.data.longBio}/>
                </div>
              </section>

              <section className="person__others">
                <div className="person__others__container">
                  <div className="person__others__container--inner">
                    <h3 className="person__others__header t-sectionTitle--large">More leadership</h3>
                    <div className="person__others__grid">
                      {
                        this.props.pagePersonQuery.pagePeople.map((other, i) => (
                          <div key={i} className="person__others__link">
                            <Link to={`/who-we-are/${other.route}`} className="person__others__link__link">
                              <div className="person__others__link__container">
                                <img className="person__others__link__profile" src={other.image.url} alt={other.name}/>
                              </div>
                            </Link>
                            <h4 className="person__others__link__name t-personCardTitle">
                              {other.name}
                            </h4>
                            <h5 className="person__others__link__title t-caption">
                              {other.title}
                            </h5>
                          </div>
                        ))
                      }
                    </div>
                  </div>
                </div>
              </section>
            </main>,
            <Footer key="Footer" mainNavigation={this.props.pagePersonQuery.footer[0].mainNavigation} contactUsPage={this.props.pagePersonQuery.footer[0].contactUsPage} />
          ]
        );
      }
    }
  }
}

export default Person;