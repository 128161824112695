import React, {
  Component
} from 'react';
import Link from '../../components/Link';
import {
  Helmet
} from 'react-helmet';
import Footer from '../../components/shared/Footer';

const {
  DateTime
} = require('luxon');

class PageWhoWeAre extends Component {

  render() {
    if (this.props.pageWhoWeAreQuery && this.props.pageWhoWeAreQuery.loading) {
      return <div className="u-hidden">Loading</div>
    } else if (this.props.pageWhoWeAreQuery && this.props.pageWhoWeAreQuery.error) {
      return <div className="u-hidden">Error</div>
    } else {
      this.data = this.props.pageWhoWeAreQuery.thisPageWhoWeAre[0];

      if (!this.data) {
        window.location.href = "/404";
        return (null);
      } else {

        const heroStyle =
          this.data.introBackground && {
            backgroundImage: `url(${this.data.introBackground.url}?h=600&q=100&fm=jpg&fl=progressive)`
          }
        const pressStyle =
          this.data.pressImage && {
            backgroundImage: `url(${this.data.pressImage.url})`
          }

        return (
          [
            <main key="WhoWeAre" className="page__whoweare">
              <Helmet>
                <title>{this.data.navigationName}</title>
                <meta name="description" content={this.data.introHeader}/>
                <meta property="og:title" content={this.data.introHeadernavigationName}/>
                <meta property="og:description" content={this.data.introHeader}/>
                <meta property="og:url" content={window.location.href}/>
                <meta property="og:image" content={this.data.introBackground.url}/>
              </Helmet>
              <section className="whoweare__hero" style={heroStyle}>
                <div className="whoweare__hero__container">
                  <div className="whoweare__hero__text">
                    <h1 className="whoweare__hero__header t-pageTitle">
                      {this.data.introHeader}
                    </h1>
                    <h2 className="whoweare__hero__copy t-copy">
                      {this.data.introBody}
                    </h2>
                  </div>
                </div>
              </section>
              <section className="whoweare__others">
                <div className="whoweare__others__container">
                  <div className="whoweare__others__grid">
                    {
                      this.data.people.map((other, i) => (
                        <div key={i} className="whoweare__others__link">
                          <Link to={`/who-we-are/${other.route}`} className="whoweare__others__link__link">
                            <div className="whoweare__others__imgContainer">
                              <img className="whoweare__others__link__profile" src={other.image.url} alt={other.name}/>
                            </div>
                          </Link>
                          <h4 className="whoweare__others__link__name t-personCardTitle">
                            {other.name}
                          </h4>
                          <h5 className="whoweare__others__link__title t-caption">
                            {other.title}
                          </h5>
                        </div>
                      ))
                    }
                  </div>
                </div>
              </section>
              <section className="whoweare__employee">
                <div className="whoweare__employee__container">
                  <div className="whoweare__employee__text">
                    <h2 className="whoweare__employee__text__header t-sectionTitle--large">Our associates</h2>
                  </div>
                    <div className="whoweare__employee__grid">
                      {
                        this.data.employees.map((other, i) => (
                          <div key={i} className="whoweare__employee__link">
                            <h4 className="whoweare__employee__link__name t-employeeTitle">
                              {other.name}
                            </h4>
                            <h5 className="whoweare__employee__link__title t-employeeCaption">
                              {other.title}
                            </h5>
                          </div>
                        ))
                      }
                    </div>
                </div>
              </section>
              {this.data.clientImages &&
                <section className="whoweare__clients">
                  <div className="whoweare__clients__container">
                    <div className="whoweare__clients__text">
                      {this.data.clientsHeader && <h2 className="whoweare__clients__text__header t-sectionTitle--large">{this.data.clientsHeader}</h2>}
                      {this.data.clientsBody && <h3 className="whoweare__clients__text__body t-copy">{this.data.clientsBody}</h3>}
                    </div>
                  </div>
                  <div className="whoweare__clients__grid">
                    {
                      this.data.clientImages.map((client, i) => (
                        <div key={i} className="whoweare__clients__link">
                          <img className="whoweare__clients__image" src={client.url} alt={client.title}/>
                        </div>
                      ))
                    }
                  </div>
                </section>
              }
              {this.data.pressClips && 
                <section className="whoweare__press">
                  <div className="whoweare__press__centerer">
                    <div className="whoweare__press__image" style={pressStyle} />
                    <div className="whoweare__press__positioner">
                      <h2 className="whoweare__press__text__header t-sectionTitle--large">{this.data.pressHeader}</h2>
                      {
                        this.data.pressClips.map((clip, i) => (
                          <div key={i} className="whoweare__press__link">
                            <h4 className="whoweare__press__dateline t-dateline">
                              {DateTime.fromISO(clip.dateline).toLocaleString().replace(new RegExp('/', 'g'), '.')}
                            </h4>
                            <h3 className="t-cardTitle">
                              <Link className="whoweare__press__link" to={clip.link}>
                                {clip.blurb}
                              </Link>
                            </h3>
                            <h5 className="whoweare__press__attribution t-caption">
                              —{clip.attribution}
                            </h5>
                          </div>
                        ))
                      }
                    </div>
                  </div>
                </section>
              }
            </main>,
            <Footer key="Footer" mainNavigation={this.props.pageWhoWeAreQuery.footer[0].mainNavigation} contactUsPage={this.props.pageWhoWeAreQuery.footer[0].contactUsPage} />
          ]
        );
      }
    }
  }
}

export default PageWhoWeAre;
