import React from 'react';
import { render } from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import ScrollToTop from './ScrollToTop'
import App from './App';

import { ApolloClient, HttpLink, ApolloProvider } from '@apollo/client';
import { IntrospectionFragmentMatcher, InMemoryCache } from 'apollo-cache-inmemory';
import introspectionQueryResultData from './assets/fragmentTypes.json';

const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData
});

const cache = new InMemoryCache({ fragmentMatcher });
const client = new ApolloClient({
  // By default, this client will send queries to the
  //  `/graphql` endpoint on the same host
  link: new HttpLink(),
  cache: cache
});

render((
  <ApolloProvider client={client}>
    <BrowserRouter>
      <ScrollToTop>
        <App />
      </ScrollToTop>
    </BrowserRouter>
  </ApolloProvider>
), document.getElementById('root'));