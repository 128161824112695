import React, { Component } from 'react';
import { graphql } from '@apollo/client/react/hoc';
import {gql} from '@apollo/client';

export default function image360WithData(WrappedComponent, options = {}) {
  const HOC = class extends Component {
    constructor(props) {
      super(props);
      this.wrapped = graphql(gql `
        query PageImage360Query {
          pageVenues(q: "fields.image360.sys.id=${props.match.params.matchedRoute}") {
            route
            name
            image360 {
              url
              title
              sys {
                id
              }
            }
          }
          moduleGalleries(q: "fields.image360.sys.id=${props.match.params.matchedRoute}") {
            image360 {
              url
              title
              sys {
                id
              }
            }
          }
        }
        `, { name: 'pageImage360Query' })(WrappedComponent);
    }

    render() {
      const Wrapped = this.wrapped;
      return <Wrapped {...this.props}/>;
    }
  };

  return HOC;
}