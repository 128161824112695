import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import ModalVideo from "../../components/ReactModalVideo";

import ogImg from "../../assets/img/og.jpg";

import Waypoint from "react-waypoint";

import classnames from "classnames";

import HeroCarousel from "../../components/home/HeroCarousel";
import HeroIntro from "../../components/home/HeroIntro";

import VenuesCarousel from "../../components/home/VenuesCarousel";
import WhoWeAre from "../../components/home/WhoWeAre";

import Footer from '../../components/shared/Footer';

class Home extends Component {
  constructor(props) {
    super(props);
    this.onTransitionEnd.bind(this);
    this.playReel = this.playReel.bind(this);
  }

  state = {
    transitionEnded: true,
    reelOpen: false
  };

  onTransitionEnd = () => {
    this.setState({ transitionEnded: true });
  };

  playReel() {
    this.setState({ reelOpen: true });
  }

  closeVideo() {
    this.setState({ reelOpen: false });
  }

  onWaypointEnter = function () {
    this._ref.classList.add("what__type--animate");
  };

  render() {
    const heroClasses = classnames("hero", {
      animate: this.state.transitionEnded
    });

    const heroContentClasses = classnames("heroContent", {
      "is-video-playing": this.state.reelOpen
    });

    if (this.props.homePageQuery && this.props.homePageQuery.loading) {
      return <div className="u-hidden">Loading</div>;
    } else if (this.props.homePageQuery && this.props.homePageQuery.error) {
      return <div className="u-hidden">Error</div>;
    } else {
      this.data = this.props.homePageQuery.globalSites[0];
      if (!this.data) {
        window.location.href = "/404";
        return null;
      } else {
        return (
          [
            <main key="home" className="home">
              <Helmet>
                <title>{this.data.homePage.introHeader}</title>
                <meta name="description" content={this.data.homePage.introBody} />
                <meta property="og:title" content={this.data.homePage.introHeader} />
                <meta property="og:url" content={window.location.href} />
                <meta property="og:image" content={ogImg} />
                <meta property="og:description" content={this.data.homePage.introBody} />
              </Helmet>
              <header className={heroClasses}>
                <HeroCarousel
                  introBackgrounds={this.data.homePage.introBackgrounds}
                />
                <ModalVideo
                  isOpen={this.state.reelOpen}
                  video={this.data.homePage.introVideoUrl}
                  onClose={() => this.closeVideo()}/>
                <div className={heroContentClasses}>
                  <svg className="logo">
                    <use xlinkHref="#marquee-dev-logo-white" href="#marquee-dev-logo-white" />
                  </svg>
                  <div className="hero__block">
                    <h1 className="t-homepageTagline hero__tagline">
                      {this.data.homePage.introHeader}
                    </h1>
                    {this.data.homePage.introVideoUrl && 
                      <button
                        className="hero__cta link link--underline t-cta--link"
                        onClick={this.playReel}>
                        {this.data.homePage.introVideoCta}
                        <svg className="icon-play">
                          <use xlinkHref="#icon-play" href="#icon-play" />
                        </svg>
                      </button>
                    }
                  </div>
                  <div className="hero__description">
                    <p className="hero__intro">{this.data.homePage.introBody}</p>
                  </div>
                </div>
              </header>
              <div className="bigQSections">
                <div className="bigQSections__underlay">
                  <svg className="bigQ right">
                    <use xlinkHref="#big-q" href="#big-q" />
                  </svg>
                  <svg className="bigQ left">
                    <use xlinkHref="#big-q" href="#big-q" />
                  </svg>
                </div>
                {this.data.homePage.venuesCarousel && 
                  <VenuesCarousel
                    header={this.data.homePage.venuesHeader}
                    slides={this.data.homePage.venuesCarousel}
                  />
                }
              </div>
              {this.data.homePage.caseStudies && 
                <WhoWeAre data={this.data.homePage.caseStudies} />
              }
            </main>,
            <Footer key="Footer" mainNavigation={this.data.mainNavigation} contactUsPage={this.data.contactUsPage} />
          ]
        );
      }
    }
  }
}

export default Home;