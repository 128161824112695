import React, { Component } from 'react';
import QuoteCard from '../shared/QuoteCard';
import CaseStudyTweet from '../case-studies/CaseStudyTweet';

class ModuleQuote extends Component {
  render() {
    return (
      <section className="moduleQuote">
        <div className="moduleQuote__container">
          {
            (() => {
              if (this.props.header !== null) {
                return (
                  [
                    <div key="column-1" className="moduleQuote__column">
                      <h2 className="t-sectionTitle--large">
                        {this.props.header}
                      </h2>
                      <p className="moduleQuote__copy t-copy">
                        {this.props.body}
                      </p>
                    </div>,
                    <div key="column-2" className="moduleQuote__column">
                      <QuoteCard quote={this.props.quote} />
                    </div>
                  ]
                )
              } else if (this.props.quote.isTweet) {
                return (
                  <CaseStudyTweet quote={this.props.quote} />
                )
              } else {
                return (
                  <QuoteCard quote={this.props.quote} />
                )
              }
            })()
          }
        </div>
      </section>
    );
  }
}

export default ModuleQuote;