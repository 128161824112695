import { Component } from 'react';
import { withRouter } from 'react-router';

class ScrollToTop extends Component {
  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      window.setTimeout(() => {
        window.scrollTo(0, 0)
      }, 600);
    }
  }

  render() {
    if ('scrollRestoration' in window.history) {
      window.history.scrollRestoration = 'manual';
    }
    return this.props.children
  }
}

export default withRouter(ScrollToTop)