import React, {
  Component
} from 'react';
import {
  TransitionGroup,
  CSSTransition
} from 'react-transition-group';

class PageTransition extends Component {
  state = {
    lastPageUrl: ''
  }

  componentDidMount() {
    this.setState({
      lastPageUrl: window.location.href
    });
  }

  shouldComponentUpdate() {
    return this.state.lastPageUrl !== window.location.href;
  }

  componentWillUpdate() {
    document.body.classList.add('is-transitioning');

    if (window.scrollY < 175) {
      document.body.classList.add('is-at-top');
    }
  }

  componentDidUpdate() {
    this.setState({
      lastPageUrl: window.location.href
    });

    setTimeout(() => {
      document.body.classList.remove('is-transitioning');
      document.body.classList.remove('is-at-top');
    }, 700);
  }

  render() {
    return (
      <TransitionGroup component="article">
        <CSSTransition
          key={this.props.location}
          classNames="PageTransition"
          timeout={{
           enter: 1100,
           exit: 1100,
          }}
        >
          {this.props.children}
        </CSSTransition>
      </TransitionGroup>
    );
  }
}

export default PageTransition;
