import React, { Component } from 'react';
import classnames from 'classnames';

class QuoteCard extends Component {
  state = {
    isGreen: this.props.quote.isGreen
  }

  render() {
    const classes = classnames(
      'quote',
      't-quote',
      {
        'quote--tan': !this.state.isGreen,
        'quote--green': this.state.isGreen
      }
    )

    return (
      <div className={classes}>
        <div className="quote__content">
          <span className="quote__mark quote__ldquo">&ldquo;</span>
          {this.props.quote.quote}
          <span className="quote__mark quote__rdquo">&rdquo;</span>
          <div className="quote__who">
            {this.props.quote.attribution}
            {
              this.props.quote.relevance !== null &&
              `, ${this.props.quote.relevance}`
            }
          </div>
        </div>
      </div>
    );
  }
}

export default QuoteCard;