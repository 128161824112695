import React, { Component } from 'react';
import { graphql } from '@apollo/client/react/hoc';
import {gql} from '@apollo/client';

export default function contactUsWithData(WrappedComponent, options = {}) {
  const HOC = class extends Component {
    constructor(props) {
      super(props);
      this.wrapped = graphql(gql `
          query PageContactUsQuery {
            thisContactUs: pageContactuses(q:"fields.route=${props.match.path.slice(1)}"){
              route
              contactIntroHeader
              contactIntroBody
              interestHeader
              interests
              buttonCTA
              contactSuccessHeader
              contactSuccessBody
              contactSuccessButtonCta
            }
          }
      `, { name: 'pageContactUsQuery' })(WrappedComponent);
    }

    render() {
      const Wrapped = this.wrapped;
      return <Wrapped {...this.props}/>;
    }
  };

  return HOC;
}