import React, {Component} from 'react';
import './styles/main.css';

import SVGDictionary from './components/SVGDictionary';
import Navbar from './components/shared/Navbar';
import Router from './Router';

class App extends Component {
  componentDidMount() {
    if (this.pathIsNotHomepage()) {
      sessionStorage.setItem('cachedHit', true);
    }
  }

  pathIsNotHomepage = () => {
    return window.location.pathname !== '/';
  }

  render() {
    return (
      [
        <SVGDictionary key="SVGDictionary"/>,
        <Navbar key="Navbar"/>,
        <Router key="Router"/>
      ]
    );
  }
}

export default App;
