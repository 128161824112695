import React, { Component } from 'react';
import Link from '../../components/Link';
import QuoteCard from '../shared/QuoteCard';
import CaseCard from '../shared/CaseCard';

class WhoWeAre extends Component {

  getMarketingImage = (card) => {
    if (card.marketingImage) {
      if (card.marketingImage.url) {
        return card.marketingImage.url
      } else {
        return "";
      }
    } else {
      return "";
    }
  }

  getMarketingTitle = (card) => {
    if (card.marketingImage) {
      if (card.marketingImage.title) {
        return card.marketingImage.title
      } else {
        return "";
      }
    } else {
      return "";
    }
  }

  render() {

    this.rightCards = this.props.data.filter((v, i) => i % 2);
    this.leftCards = this.props.data.filter((v, i) => !(i % 2));

    return (
      <div className="who">
        <div className="who__content who__content--desktop">
          <div className="who__column who__column--left">
            {
              this.leftCards.map((card, i) => {
                switch (card.__typename) {
                  case "PageCaseStudy":
                    var links = [];
                    if (card.venue) {
                      links.push({
                        name: card.venue.name,
                        route: `our-venues/${card.venue.route}`
                      });
                    }
                    card.relatedServices.forEach(function(s){
                      links.push(s)
                    });
                    return(
                      <CaseCard
                        key={i}
                        img={this.getMarketingImage(card)}
                        title={this.getMarketingTitle(card)}
                        label={card.name}
                        tags={links}
                        route={card.route}
                      />
                    )
                  case "ComponentQuote":
                    return (
                      <div key={i} className="quoteCard">
                        <QuoteCard quote={card} />
                      </div>
                    )
                  default:
                    return <div></div>
                }
              })
            }
          </div>
          <div className="who__column who__column--right">
            {
              this.rightCards.map((card, i) => {
                switch (card.__typename) {
                  case "PageCaseStudy":
                    var links = [];
                    if (card.venue) {
                      links.push({
                        name: card.venue.name,
                      route: `our-venues/${card.venue.route}`
                      });
                    }
                    card.relatedServices.forEach(function(s){
                      links.push(s)
                    });
                    return(
                      <CaseCard
                        key={i}
                        img={this.getMarketingImage(card)}
                        title={this.getMarketingTitle(card)}
                        label={card.name}
                        tags={links}
                        route={card.route}
                      />
                    )
                  case "ComponentQuote":
                    return (
                      <div key={i} className="quoteCard">
                        <QuoteCard quote={card} />
                      </div>
                    )
                  default:
                    return <div></div>
                }
              })
            }
          </div>
        </div>

        <div className="who__content who__content--mobile">
          <div className="who__column">
            {
              this.props.data.map((card, i) => {
                switch (card.__typename) {
                  case "PageCaseStudy":
                    var links = [];
                    if (card.venue) {
                      links.push({
                        name: card.venue.name,
                        route: `/our-venues/${card.venue.route}`
                      });
                    }
                    card.relatedServices.forEach(function(s){
                      links.push({
                        name: s.name,
                        route: `/what-we-do/${s.route}`
                      })
                    });
                    return(
                      <CaseCard
                        key={i}
                        img={this.getMarketingImage(card)}
                        title={this.getMarketingTitle(card)}
                        label={card.name}
                        tags={links}
                        route={card.route}
                      />
                    )
                  case "ComponentQuote":
                    return (
                      <div key={i} className="quoteCard">
                        <QuoteCard quote={card} />
                      </div>
                    )
                  default:
                    return (
                      <div/>
                    )
                }
              })
            }
          </div>
        </div>

        <div className="who__ctaContainer">
          <Link to="/what-we-do" className="who__cta button">See more work</Link>
        </div>
      </div>
    );
  }
}

export default WhoWeAre;