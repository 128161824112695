import React, { Component } from 'react';
import Link from '../../components/Link';
import classnames from 'classnames';
import Waypoint from 'react-waypoint';

class CaseCard extends Component {
  state = {
    inView: false
  }

  onWaypointEnter = () => {
    this.setState({
      inView: true
    });
  }

  render() {
    const cardClasses = classnames(
      'caseCard', {
        'caseCard--animated': this.state.inView
      }
    );

    return (
      <Waypoint
        onEnter={this.onWaypointEnter}
        bottomOffset="30%"
      >
        <figure className={cardClasses}>
          <Link to={`/case-studies/${this.props.route}`} className="caseCard__img">
            <img src={`${this.props.img}?w=727`} alt={this.props.title} className="caseCard__img" />
          </Link>
          <div className="caseCard__tags">
            {
              this.props.tags.map(function(tag, i) {
                var sub="/"
                if (tag.__typename === "PageService") {
                  sub+="what-we-do/"
                }
                return (<Link key={i} to={sub + tag.route} className="caseCard__tag link link--underline t-tag--small">
                  {tag.name}
                </Link>)
              })
            }
          </div>
          <figcaption className="caseCard__label t-cardTitle">
            <Link to={`/case-studies/${this.props.route}`}>
              {this.props.label}
            </Link>
          </figcaption>
          <div className="caseCard__imgContainer">
            <img src={`${this.props.img}?w=727`} alt="" />
          </div>
        </figure>
      </Waypoint>
    );
  }
}

export default CaseCard;