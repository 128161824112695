import React, { Component } from 'react';
import Link from '../../components/Link';
import { Helmet } from 'react-helmet';
import WrigleyvilleMap from '../../components/venues/WrigleyvilleMap';
import Footer from '../../components/shared/Footer';

class PageOurVenues extends Component {

  render() {
    if (this.props.pageOurVenuesQuery && this.props.pageOurVenuesQuery.loading) {
      return <div className="u-hidden">Loading</div>
    } else if (this.props.pageOurVenuesQuery && this.props.pageOurVenuesQuery.error) {
      return <div className="u-hidden">Error</div>
    } else {
      this.data = this.props.pageOurVenuesQuery.thisPageOurVenues[0];

      if (!this.data) {
        window.location.href = "/404";
        return (null);
      } else {

        var heroStyle = {
          backgroundImage: `linear-gradient(rgba(28,33,39,0.7), rgba(28,33,39,0.7)), url(${this.data.introBackground.url}?w=${window.innerWidth}&q=90)`
        }

        return (
          [
            <main key="OurVenues" className="page__ourvenues">
              <Helmet>
                <title>{this.data.introHeader}</title>
                <meta name="description" content={this.data.introBody}/>
                <meta property="og:title" content={this.data.introHeader}/>
                <meta property="og:description" content={this.data.introBody}/>
                <meta property="og:url" content={window.location.href}/>
                <meta property="og:image" content={this.data.introBackground.url}/>
              </Helmet>

              <section className="ourvenues__hero" style={heroStyle}>
                <div className="ourvenues__hero__container">
                  <div className="ourvenues__hero__text">
                    <h1 className="ourvenues__hero__header t-pageTitle">{this.data.introHeader}</h1>
                    <h2 className="ourvenues__hero__copy t-copy">{this.data.introBody}</h2>
                  </div>
                </div>
              </section>
              {/*<section className="ourvenues__imagemap">
                <WrigleyvilleMap></WrigleyvilleMap>*
              </section>*/}
              <section className="ourvenues__clients">
                {this.data.partnerLogos && 
                  <div className="ourvenues__clients__grid">
                    {
                      this.data.partnerLogos.map((sponsor, i) => (
                        <div key={i} className="ourvenues__clients__link">
                          <img className="ourvenues__clients__image" src={sponsor.url} alt={sponsor.title}/>
                        </div>
                      ))
                    }
                  </div>
                }
              </section>
              <section className="ourvenues__venuelist">
                <div className="ourvenues__venuelist__centerer">
                  {
                    this.data.venues.map((venue, i) => (
                      <div key={i} className="ourvenues__venue">
                        <div className="ourvenues__venue__image">
                          <img src={`${venue.hero.url}?w=367&h=240&q=100`} alt={venue.hero.title}/>
                        </div>
                        <div className="ourvenues__venue__text">
                          <h2 className="ourvenues__venue__name t-sectionTitle--large">
                            {venue.name}
                          </h2>
                          <p className="ourvenues__venue__title t-copy">
                            {venue.overviewBody}
                          </p>
                          <Link to={`/our-projects/${venue.route}`} className="ourvenues__venue__cta link t-cta--link">
                            Learn More
                          </Link>
                        </div>
                      </div>
                    ))
                  }
                </div>
              </section>
            </main>,
            <Footer key="Footer" mainNavigation={this.props.pageOurVenuesQuery.footer[0].mainNavigation} contactUsPage={this.props.pageOurVenuesQuery.footer[0].contactUsPage} />
          ]
        );
      }
    }
  }
}

export default PageOurVenues;