import React, { Component } from 'react';
import { Helmet } from 'react-helmet';

import VenueHeader from '../../components/venues/VenueHeader';
import VenueStats from '../../components/venues/VenueStats';
import ModuleGallery from '../../components/shared/ModuleGallery';
import ModuleVideo from '../../components/shared/ModuleVideo';
import ModuleQuote from '../../components/shared/ModuleQuote';
import ModuleFeatures from '../../components/shared/ModuleFeatures';
import VenueAside from '../../components/venues/VenueAside';
import VenueCaseStudyCarousel from '../../components/venues/VenueCaseStudyCarousel';
import VenueFooter from '../../components/venues/VenueFooter';

import Footer from '../../components/shared/Footer';

class Venue extends Component {

  constructor(props) {
    super(props);
    this.onTransitionEnd.bind(this);
  }

  state = {
    transitionEnded: false
  }

  onTransitionEnd = () => {
    this.setState({ transitionEnded: true });
  }

  pathIsExactly = (str) => {
    return window.location.pathname === str;
  }

  render() {
    if (this.props.venuePageQuery && this.props.venuePageQuery.loading) {
      return <div className="u-hidden">Loading</div>
    } else if (this.props.venuePageQuery && this.props.venuePageQuery.error) {
      return <div className="u-hidden">Error</div>
    } else {
      this.data = this.props.venuePageQuery.thisVenue[0];

      if (!this.data) {
        window.location.href = "/404";
        return (null);
      } else {
        return (
          [
            <main key="Venue" className="venue">
              <Helmet>
                <title>{this.data.name}</title>
                <meta name="description" content={this.data.overviewBody}/>
                <meta property="og:title" content={this.data.name}/>
                <meta property="og:description" content={this.data.overviewBody}/>
                <meta property="og:url" content={window.location.href}/>
                <meta property="og:image" content={this.data.hero.url}/>
              </Helmet>

              <VenueHeader
                name={this.data.name}
                location={this.data.location}
                hero={this.data.hero}
                overview={this.data.overviewBody}
                cta={this.data.linkCta}
                url={this.data.linkUrl}
                image360={this.data.image360}
              />

              {
                this.data.bodySections.map((section, i) => {
                  switch (section.__typename) {
                    case 'ModuleStats':
                      let ParkCarousel;
                      if (this.pathIsExactly('/our-venues/park-at-wrigley')) {
                        ParkCarousel =
                          <VenueCaseStudyCarousel
                            key="ParkCarousel"
                            backrefs={this.data._backrefs.pageCaseStudies__via__venue}
                          />;
                      }
                      return (
                        [
                          <VenueStats
                            key={i}
                            header={section.header}
                            body={section.body}
                            stats={section.stats}
                          />,
                          ParkCarousel
                        ]
                      )
                    case 'ModuleVideo':
                      return (
                        <ModuleVideo
                          key={i}
                          header={section.header}
                          video={section.videoUrl}
                        />
                      )
                    case 'ModuleGallery':
                      return (
                        <ModuleGallery
                          key={i}
                          images={section.images}
                          image360={section.image360}
                          header={section.header}
                          body={section.body}
                          above={section.headerBodyTextLocation}
                          linkText={section.linkText}
                          linkUrl={section.linkUrl}
                          fullWidthBottomImage={section.fullWidthBottomImage}
                          caption={section.caption}
                        />
                      )
                    case 'ModuleQuote':
                      let WrigleyFieldCarousel;
                      if (this.pathIsExactly('/our-venues/wrigley-field')) {
                        {/*WrigleyFieldCarousel =
                          <VenueCaseStudyCarousel
                            key="WrigleyFieldCarousel"
                            backrefs={this.data._backrefs.pageCaseStudies__via__venue}
                          />;*/}
                      }
                      return (
                        [
                          <ModuleQuote
                            key={i}
                            header={section.header}
                            body={section.body}
                            quote={section.quote}
                          />,
                          WrigleyFieldCarousel
                        ]
                      )
                    case 'ModuleFeatures':
                      return (
                        <ModuleFeatures
                          key={i}
                          header={section.header}
                          body={section.body}
                          features={section.features}
                        />
                      )
                    case 'ModuleAside':
                      return (
                        <VenueAside
                          key={i}
                          header={section.header}
                          body={section.body}
                          bulletPoints={section.bulletPoints}
                          isWide={section.isWide}
                        />
                      )
                    default:
                      return <div key={i} />
                  }
                })
              }

              <VenueFooter
                footerImage={this.data.footerImage}
                pageVenues={this.data.footerVenues}
              />
            </main>,
            <Footer key="Footer" mainNavigation={this.props.venuePageQuery.footer[0].mainNavigation} contactUsPage={this.props.venuePageQuery.footer[0].contactUsPage} />
          ]
        );
      }
    }
  }
}

export default Venue;