import React, {
  Component
} from 'react';
import Link from '../../components/Link';
import Swiper from 'swiper/dist/js/swiper.js';

class VenuesCarousel extends Component {

  componentDidMount() {
    setTimeout(function() {
      var imageSwiper = new Swiper('.image-swiper', {
        pagination: {
          el: '.image-pagination',
          type: 'custom',
          renderCustom: function(swiper, current, total) {
            return '<span>0' + current + ' / 0' + total + '</span>';
          }
        },
        navigation: {
          nextEl: '.swiper-next',
          prevEl: '.swiper-prev'
        },
        loop: true,
        slidesPerView: 'auto',
        spaceBetween: 10,
        grabCursor: true,
        slideToClickedSlide: true,
        longSwipesRatio: 0.2,
        breakpoints: {
          480: {
            slidesOffsetBefore: 0,
            centeredSlides: true
          },
          767: {
            slidesOffsetBefore: 0,
            centeredSlides: true
          },
          999: {
            slidesOffsetBefore: 80,
            centeredSlides: false
          },
          1279: {
            slidesOffsetBefore: 100,
            centeredSlides: false
          },
          1599: {
            slidesOffsetBefore: 140,
            centeredSlides: false
          },
          3000: {
            slidesOffsetBefore: 160,
            centeredSlides: false
          }
        }
      });

      var labelSwiper = new Swiper('.label-swiper', {
        direction: 'vertical',
        slidesPerView: 'auto',
        autoHeight: true,
        spaceBetween: 20,
        loop: true,
        centeredSlides: true
      });

      labelSwiper.controller.control = imageSwiper;
      imageSwiper.controller.control = labelSwiper;

    }, 1000);
  }

  render() {
    return (
      <section className="venues">
        <div className="venues__container">
          <div className="venues__intro">
            <h1 className="t-pageTitle venues__title">
              {this.props.header}
            </h1>
            <Link to="/our-projects" className="t-cta--link link">
              Our Projects
            </Link>
          </div>

          <div className="swiper-controls">
            <div className="swiper-prev">
              <svg className="arrow-left-large">
                <use xlinkHref="#arrow-left-large" href="#arrow-left-large"></use>
              </svg>
            </div>
            <div className="swiper-next">
              <svg className="arrow-right-large">
                <use xlinkHref="#arrow-right-large" href="#arrow-right-large"></use>
              </svg>
            </div>
          </div>
        </div>

        <div className="swipers">
          <div className="swiper-container image-swiper">
            <div className="swiper-wrapper image-wrapper">
              {this.props.slides && 
                this.props.slides.map((slide, i) => (
                  <div key={i} className="swiper-slide image-slide">
                    <Link to={`/our-projects/${slide.route}`} className="image-slide__venuesLink">
                      <div
                        className="image-slide__container"
                        style={{
                          backgroundImage: `url(${slide.marketingImage.url})`
                        }}
                      />
                    </Link>
                    <h2 className="t-cardTitle image-slide-label">{slide.name}</h2>
                  </div>
                ))
              }
            </div>
            <div className="image-pagination t-number--small"></div>
          </div>

          <div className="swiper-container swiper-no-swiping label-swiper">
            <div className="swiper-wrapper">
              {this.props.slides && 
                this.props.slides.map((slide, i) => (
                  <div key={i} className="swiper-slide label-slide">
                    <h1 className="t-quote">{slide.marketingDescription}</h1>
                  </div>
                ))
              }
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default VenuesCarousel;
