import React, { Component } from 'react';
import classnames from 'classnames';
import Link from '../../components/Link';
import Waypoint from 'react-waypoint';

class WaypointImage extends Component {
  state = {
    inView: false,
    allowEnter: false
  }

  onWaypointEnter = () => {
    if (this.state.allowEnter) {
      this.setState({
        inView: true
      });
    }
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({
        allowEnter: true
      });
    }, 700);
  }

  render() {
    const classes = classnames(
      'moduleGallery__img', {
        'moduleGallery__img--animate': this.state.inView
      }
    )

    return (
      <Waypoint
        onEnter={this.onWaypointEnter}
        bottomOffset="30%"
        scrollableAncestor={window}
      >
        <div className={classes}>
          {this.props.children}
        </div>
      </Waypoint>
    )
  }
}

class ModuleGallery extends Component {

  pathIsExactly = (str) => {
    return window.location.pathname === str;
  }

  render() {
    const LearnMore = () => (
      <div>
        {
          this.props.linkUrl !== null &&
          <Link to={this.props.linkUrl} className="link t-cta--link moduleGallery__link">
            {this.props.linkText}
          </Link>
        }
      </div>
    );

    const galleryClasses = classnames(
      'moduleGallery',
      `moduleGallery--${this.props.images.length}`, {
        'moduleGallery--reverse': this.props.fullWidthBottomImage,
        'moduleGallery--fullWidth': this.pathIsExactly('/our-venues/hotel-zachary'),
        'moduleGallery--360': this.props.image360 !== null
      }
    )

    return (
      <section className="moduleGallery__container">
        {
          (this.props.header !== null && this.props.above) &&
          <div className="moduleGallery__description">
            <h2 className="moduleGallery__description__title t-sectionTitle--large">
              {this.props.header}
            </h2>
            <p className="moduleGallery__description__copy t-copy">
              {this.props.body}
            </p>
            <LearnMore
              linkUrl={this.props.linkUrl}
              linkText={this.props.linkText}
            />
          </div>
        }

        <div className={galleryClasses}>
          {
            this.props.images.length <= 2 ?

            this.props.images.map((image, i) => (
              <WaypointImage key={i}>
                <img src={image.url} alt={image.title} />
              </WaypointImage>
            ))

            :

            this.props.images.length === 3 && [
              <WaypointImage key="top">
                <img src={this.props.images[0].url} alt={this.props.images[0].title} />
              </WaypointImage>,
              <div key="bottom" className="moduleGallery__imgNowrap">
                <WaypointImage>
                  <img src={this.props.images[1].url} alt={this.props.images[1].title} />
                </WaypointImage>
                <WaypointImage>
                  <img src={this.props.images[2].url} alt={this.props.images[2].title} />
                </WaypointImage>
              </div>
            ]
          }

          {
            this.props.image360 !== null &&
            <Link
              to={`/image360/${this.props.image360.sys.id}`}
              key={`${this.props.image360.url}`}
              className="venue__360ViewCtaContainer t-cta--link"
            >
              <div
                className="venue__360ViewCta"
                style={{
                  backgroundImage: `url(${this.props.image360.url}?w=250)`
                }}
              >
                <div className="venue__360ViewCta__text">
                  <svg className="icon-360">
                    <use xlinkHref="#icon-360" href="#icon-360"></use>
                  </svg>
                  See 360&deg; view
                  <svg className="icon-caret-right">
                    <use xlinkHref="#caret-right" href="#caret-right"></use>
                  </svg>
                </div>
              </div>
            </Link>
          }
        </div>
        {
          this.props.caption &&
          <span className="t-gallery-caption">{this.props.caption}</span>
        }
        {
          (this.props.header !== null && !this.props.above) &&
          <div className="moduleGallery__description">
            <h2 className="moduleGallery__description__title t-sectionTitle--large">
              {this.props.header}
            </h2>
            <p className="moduleGallery__description__copy t-copy">
              {this.props.body}
            </p>
            <LearnMore
              linkUrl={this.props.linkUrl}
              linkText={this.props.linkText}
            />
          </div>
        }
      </section>
    );
  }
}

export default ModuleGallery;